import gql from "graphql-tag";


export const ClientProfileFragments = {
    details: gql`
      fragment ClientProfileDetails on ClientProfile {
        id
        firstName
        #          lastName
        sessionsPerWeek
        goal
        imageUrl
        thumbnailUrl
        createdAt
        trainerProfiles {
          id
          user {
            clientProfile {
              id
              firstName
              #                      lastName
            }
          }
        }
      }
    `,
    headerInformation: gql`
      fragment ClientProfileHeaderInformation on ClientProfile {
        id
        firstName
        #        lastName
        createdAt
        thumbnailUrl
        imageUrl
      }
    `,

}

export const SessionFragments = {
    details: gql`
      fragment SessionDetails on Session {
        id
        name
        startTime
        spaces
        bookable
        room { id }
        trainer { id user { id clientProfile {
          id
          firstName
          #            lastName
        } } }

        type

        description
        duration
        cancelledOn
        recurring
        repeats
        customSessionType {
          id
          name
        }
      }
    `,
    alterations: gql`
      fragment SessionAlterations on Session {
        alterations(where: {
          OR: [
            {
              from_gte: $from
              from_lte: $to
            },
            {
              to_gte: $from
              to_lte: $to
            }
          ]
        }) {
          id
          from
          to
        }
      }
    `
}

export const WorkoutFragments = {
    headerInfo: gql`
      fragment WorkoutHeaderInfo on Workout {
        id
        name
        mode
        imageUrl
        isTemplate
        isPlanned
        isInstance
        isGuided
        isViewableByGymClients
        createdAt
        duration
        defaultRestBetweenSets
        status
        notes
        baseWorkout {
          id
        }
        type {
          id
          name
        }
        targetGroup {
          id
          name
        }
      }
    `,
    externalVideos: gql`
      fragment WorkoutExternalVideos on Workout {
        externalVideos {
          id
          createdAt
          updatedAt
          provider
          url
          originId
          name
          caption
          order
        }
      }
    `,
    exerciseDetails: gql`
      fragment WorkoutExerciseDetails on Workout {
        exerciseEntries {
          id
          setName
          tempo
          notes
          exertion
          index
          warmupSets
          definitions {
            id
            duration
            intensity
            reps
            amrap
            rir
            rest
            alan
            repsMax
            rpe
            index
            weight
            complete
            updatedAt
          }
          exercise {
            id
            createdAt
            name
            imageUrl
            definitionType
            defaultTempo
            defaultDefinition {
              id
              duration
              intensity
              reps
              repsMax
              rpe
              weight
              index
            }

            primaryMuscles {
              id
              commonName
              group
            }
            secondaryMuscles {
              id
              commonName
              group
            }
            equipments {
              id
              name
            }
          }
        }
      }
    `
}

export const ExerciseFragments = {
    headerInfo: gql`
      fragment ExerciseHeaderInfo on Exercise {
        id
        name

      }
    `
}


export const NutritionFragments = {
    nutritionPlanDayDetails: gql`
      fragment NutritionPlanDayDetails on NutritionPlanDay {
        id
        name
        meals {
          id
          name
          ingredients {
            id
            amount
            friendlyUnitAmount
            friendlyUnitName
            ingredient {
              id
              name
              protein
              fat
              carbohydrates
              measures {
                id
                amount
                friendlyUnitAmount
                friendlyUnitName
              }
            }
          }
        }
      }
    `
}

export const MuscleFragments = {
    MuscleDetails: gql`
      fragment MuscleDetails on Muscle {
        id
        commonName
        latinName
        group
        front
        portion
        offset
      }
    `
}


export const RoomFragments = {
    roomDetails: gql`
      fragment RoomDetails on Room {
        id
        capacity
        name
        description
        status
      }
    `
}


export const CourseFragments = {
  CourseDetails: gql`
    fragment CourseDetails on Course {
      id
      name
      enrolments {
        client {
          id
        }
        status
        startDate
      }
      groupEnrolments {
        id
        status
        startDate
        clientGroup {
          id
          name
        }
      }
      entries {
        id
        name
        details
        source
        type
        linkedId
        day
      }
    }
  `
}


export const FormFragments = {
  FormDetails: gql`
    fragment FormDetails on Form {
      id
      type
      name
      questions
      draftQuestions
      required
      status
      repeatsOnDays
      responses(first: 1, where: {completedOn_not: null}){
        id
      }
    }
  `
}
